<template>
  <div>
    <div class="synopsis-page">
      <img class="tp-img" src="../../assets/images/synopsis/ab-ban.png" />
      <div class="section">
        <div class="synopsis">
          <h2>公司简介</h2>
        </div>
        <div class="synopsis-text">
          <h1>
            <span>领跑，</span>
            有机垃圾处理行业
          </h1>
          <p>
            <span style="color: green; font-size: 25px; font-weight: bold"
              >苏州腾康环保科技有限公司</span
            >是一家集有机垃圾处理技术研发、工程设计、设备制作、垃圾分类、收集运输、运营管理为一体的高新技术企业。公司专注于餐厨垃圾、厨余垃圾、果蔬垃圾等有机垃圾处理领域，致力于为客户提供“有机垃圾”无害化、减量化、资源化一站式解决方案。
          </p>
          <p>
            同时我司独立自主研发的3D数字孪生技术、智慧收运数字化平台已成功运用于餐厨项目，显著地降低了项目运营成本，提高了生产效率。
          </p>
          <p>
            公司团队已在“有机垃圾”领域的技术研究和工程实践多年，与专业科研机构合作，不断研发创新有机垃圾处理的先进工艺技术。团队主要人员参与了“十一五”、“十二五”等科技支撑项目的研发和示范项目的实施，拥有专利技术和专有技术
            <span style="color: coral; font-size: 20px; font-weight: bold"
              >25</span
            >
            多项，自主开发了领先的餐厨垃圾、厨余垃圾等有机垃圾无害化处理与资源化利用成套设备
            <span style="color: coral; font-size: 20px; font-weight: bold"
              >20</span
            >多个系列。已有多个餐厨垃圾、厨余垃圾等有机垃圾处理项目的设计、安装、调试、收运及生产运行管理的成功经验。
          </p>
        </div>
        <div class="synopsis-img">
          <div class="tp-video">
            <video
              width="640"
              height="368"
              style="margin: auto"
              autoplay
              src="../../assets/images/synopsis/tkfull.mp4"
            >
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "companyprofile",
  data() {
    return {};
  },
};
</script>
<style scoped>
.tp-img {
  height: 380px;
  width: 100%;
}
.tp-video {
  margin: 20px 0px 20px 300px;
}
.synopsis-page img {
  margin: auto;
  display: block;
}
.synopsis-page {
  overflow: hidden;
  min-width: 1250px;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #fff;
}
.synopsis-img {
  width: 100%;
  height: 100%;
}
.synopsis-img img {
  max-width: 100%;
  display: block;
  border: 0;
}
.synopsis-text {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
}
.synopsis-text h1 {
  font-size: 21px;
  color: #000;
  padding: 10px 0;
}
.synopsis-text h1 span {
  font-weight: bold;
  font-size: 26px;
  color: #0e6eb8;
}
.synopsis-text p {
  line-height: 35px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}
.plan-text {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
  line-height: 35px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}
.plan-img img {
  width: 1200px;
  margin: auto;
  text-align: center;
  background-size: 100% 100%;
}
.plan-img img :hover {
  background-size: 110% 110%;
}
.section {
  width: 1200px;
  overflow: hidden;
  padding: 30px 0;
  margin: auto;
}
.back-page {
  background: #fff;
}
.synopsis {
  text-align: center;
  width: 100%;
}
.synopsis i {
  display: block;
  width: 2px;
  height: 32px;
  margin: auto;
  background: #b5b5b5;
}
.synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 5px 0;
}
.synopsis em {
  display: block;
  width: 37px;
  height: 4px;
  margin: auto;
  background: #76c817;
}
.synopsis p {
  color: #6c6c6c;
  font-size: 14px;
  padding: 5px 0;
  text-transform: uppercase;
}
</style>
